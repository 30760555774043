var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"window-table-docs-step"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar un producto...","outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchProd),callback:function ($$v) {_vm.searchProd=$$v},expression:"searchProd"}})],1),(
      _vm.project.project_rol.id_project_rol !== 3 ||
        _vm.user.person.types_person.id === 3
    )?_c('v-row',[_c('v-btn',{staticClass:"fvl-btn green-military",attrs:{"small":"","elevation":"1"},on:{"click":_vm.openNewProduct}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("add_circle")]),_vm._v("Añadir ")],1)],1):_vm._e(),_c('v-data-table',{attrs:{"id":"products-step-data-table","headers":_vm.productsheaders,"items":_vm.stepSelected.products,"search":_vm.searchProd},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-link"},[_vm._v(" "+_vm._s(_vm.getFormatedDate(item.date))+" ")])]}},(
        _vm.project.project_rol.id_project_rol !== 3 ||
          _vm.user.person.types_person.id === 3
      )?{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteProductStep(item.id)}}},[_vm._v(" delete ")])]}}:null],null,true)}),_c('SnackBarEntity',{attrs:{"colorSnack":_vm.colorSnackProductsStep,"snackbarMessage":_vm.snackbarMessageProductsStep,"snack":_vm.snackProductsStep},on:{"action":_vm.hideSnackBarProductsStep}}),_c('DialogConfirmEntity',{attrs:{"showModal":_vm.dialogConfirmProductStep},on:{"action":_vm.deleteProduct,"actionClose":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }