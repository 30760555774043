<template>
  <v-navigation-drawer
    fixed
    right
    :value="drawerUpdateDocument"
    class="navigation-projects"
  >
   <!-- // Header Links -->
    <v-row id="nav-drawer-header" class="ndh-solid">
      <div>
        <span class="span-title">{{ project.project.name }} / </span>
        <span class="span-subtitle">Editar Archivo</span>
      </div>
      <div>
        <button icon class="btn-close-drawer" primary  @click.stop="closedrawerUpdateDocument">
          <v-icon>menu_open</v-icon>
        </button>
      </div>
    </v-row>
    <!-- // Header Links -->
  
    <form id="form-update-docs" @submit.prevent="sendFormUpdateDocument">
      <v-row class="fvl-w-100 d-flex justify-space-between">
       
          <v-autocomplete
            prepend-icon="stop"
            v-model="$v.stepSelected.$model"
            :items="getStepsForSelectWithProject"
            placeholder="Selecciona una etapa"
            :error-messages="stepErrors"
          ></v-autocomplete>
         
      </v-row>
     
         <v-row class="fvl-checkbox fvl-w-100 d-flex justify-space-between">
     
          <v-checkbox
            v-model="isPublic"
            label="Público"
            :disabled="
              project.project_rol.id_project_rol === 3 &&
                user.person.types_person.id !== 3
            "
          ></v-checkbox>
       
        
          <v-checkbox
            v-model="isPublicVisor"
            label="Mostrar en Visor"
            :disabled="
              project.project_rol.id_project_rol === 3 &&
                user.person.types_person.id !== 3
            "
          ></v-checkbox>
       
      </v-row>

      <div id="nd-commands" class="container-commands-upload-file">
        <v-row class="fvl-w-100 d-flex justify-end">
       
          <v-btn
            class="fvl-btn green-primary"
            elevation="1"
            type="submit"
            :disabled="$v.$invalid"
          >
            <v-icon left small>save</v-icon>Guardar
          </v-btn>
        </v-row>
      </div>
      <!-- <v-row class="d-flex">
        <v-col class="d-flex justify-end">
          <v-btn
            primary
            type="submit"
            color="success"
            class="btn-upload"
            :disabled="$v.$invalid"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row> -->
    </form>

    <LoaderEntity :loading="loadingUpdateDocument" />
    <SnackBarEntity
      :colorSnack="colorSnackUpdateDocument"
      :snackbarMessage="snackbarMessageUpdateDocument"
      :snack="snackUpdateDocument"
      @action="hideSnackBarUpdateDocument"
    />
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { LoaderEntity, SnackBarEntity } from "../../_commons";
export default {
  name: "NavigationDrawerUpdateDocument",
  mixins: [validationMixin],
  validations: {
    stepSelected: { required },
  },
  components: {
    LoaderEntity,
    SnackBarEntity,
  },
  data: () => ({
    stepSelected: 0,
    isPublic: false,
    isPublicVisor: false,
    loadingUpdateDocument: false,
    snackUpdateDocument: false,
    colorSnackUpdateDocument: "",
    snackbarMessageUpdateDocument: "",
  }),
  computed: {
    ...mapState({
      drawerUpdateDocument: (state) => state.projects.drawerUpdateDocument,
      project: (state) => state.projects.projectSelected,
      document: (state) => state.projects.documentToEdit,
      user: (state) => state.user,
    }),
    ...mapGetters(["getStepsForSelectWithProject"]),
    stepErrors() {
      const errors = [];
      if (!this.$v.stepSelected.$dirty) return errors;
      !this.$v.stepSelected.required && errors.push("Seleccionar una etapa es obligatorio.");
      return errors;
    },
  },
  methods: {
    hideSnackBarUpdateDocument() {
      this.snackUpdateDocument = false;
    },
    async setDataToEdit() {
      if (this.document) {
        this.stepSelected = this.document.stepDocumentId;
        this.description = this.document.description;
        this.isPublic =
          this.project.project_rol.id_project_rol === 3 &&
          this.user.person.types_person.id !== 3
            ? 1
            : this.document.is_public === 1
            ? true
            : false;
        this.isPublicVisor =
          this.project.project_rol.id_project_rol === 3 &&
          this.user.person.types_person.id !== 3
            ? 1
            : this.document.is_public_visor === 1
            ? true
            : false;
      }
    },
    closedrawerUpdateDocument() {
      this.$store.dispatch("setDrawerUpdateDocumentAction", false);
    },
    async sendFormUpdateDocument() {
      this.loadingUpdateDocument = true;
      const formData = {
        id_project: this.project.project.id,
        id_document: this.document.document_id,
        id_step: this.stepSelected,
        is_public:
          this.project.project_rol.id_project_rol === 3 &&
          this.user.person.types_person.id !== 3
            ? 1
            : this.isPublic
            ? 1
            : 0,
        is_public_visor:
          this.project.project_rol.id_project_rol === 3 &&
          this.user.person.types_person.id !== 3
            ? 1
            : this.isPublicVisor
            ? 1
            : 0,
        person_id: this.user.person.id,
      };
      console.log(formData);
      const { status } = await this.$store.dispatch(
        "updateDocumentProject",
        formData
      );

      if (status >= 200 && status < 300) {
        //si se cargo correctamente buscamos proyecto
        this.stepSelected = 0;
        this.productSelected = 0;
        this.quantity = "";
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackUpdateDocument = true;
        this.colorSnackUpdateDocument = "success";
        this.snackbarMessageUpdateDocument =
          "Documento actualizado correctamente";
        this.loadingUpdateDocument = false;
        this.closedrawerUpdateDocument();
      } else {
        this.snackUpdateDocument = true;
        this.colorSnackUpdateDocument = "danger";
        this.snackbarMessageUpdateDocument = "Error, intente nuevamente";
        this.loadingUpdateDocument = false;
      }
    },
  },
  async created() {
    this.loadingUpdateDocument = false;
    this.snackFileloadingUpdateDocument = false;
  },
  watch: {
    document: {
      handler() {
        this.setDataToEdit();
      },
      deep: true,
      immediate: true,
    },
  },
};


</script>
<style lang=""></style>
