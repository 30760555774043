export const productsheaders = [
    {
        text: "Cant.",
        align: "start",
        value: "quantity",
    },
    {
        text: "U. Med.",
        align: "start",
        value: "unit",
    },
    {
        text: "Fecha",
        align: "start",
        value: "date",
    },
    {
        text: "Descripción",
        align: "start",
        value: "description_product",
    },
    {
        text: "Marca",
        align: "start",
        value: "description_sub_brand",
    },

    { text: "Acciones", align: "start", value: "actions", sortable: false },
];

export const docsheaders = [
    {
        text: "Nombre",
        align: "start",
        value: "description",
        width: "280px",
    },
    {
        text: "Alc.",
        align: "start",
        value: "is_public",
        width: "80px",
    },
    {
        text: "Fecha",
        align: "start",
        value: "fecha",
        width: "150px",
    },
    {
        text: "Tam.",
        align: "start",
        value: "slot",
        width: "80px",
    },
    { text: "Acciones", align: "start", value: "actions", width: "50px", sortable: false },
];
export const linksheaders = [
    {
        text: "Link",
        align: "start",
        value: "name",
    },   
    {
        text: "Alcance",
        align: "start",
        value: "is_public",
    },
    { text: "Acciones", align: "start", value: "actions", sortable: false },
];