var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"navigation-projects",attrs:{"fixed":"","right":"","value":_vm.drawerFiles,"id":"nav-drawer-files"}},[_c('v-row',{staticClass:"ndh-solid",attrs:{"id":"nav-drawer-header"}},[_c('div',[_c('span',{staticClass:"span-title"},[_vm._v(_vm._s(_vm.project.project.name)+" / ")]),_c('span',{staticClass:"span-subtitle"},[_vm._v("Todos los Archivos")])]),_c('div',[_c('button',{staticClass:"btn-close-drawer",attrs:{"icon":"","primary":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeFiles.apply(null, arguments)}}},[_c('v-icon',[_vm._v("menu_open")])],1)])]),_c('div',{attrs:{"id":"container-table-drawler"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAllDocuments,"item-key":"name","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"container-search-files",attrs:{"label":"Buscar","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"link-non-decoration",attrs:{"href":_vm.getLink(item.link),"target":"_blank","download":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-link"},[_vm._v(" "+_vm._s(item.is_public == 1 ? "Públ." : "Priv.")+" ")])]}},{key:"item.is_public_visor",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-link"},[_vm._v(" "+_vm._s(item.is_public_visor == 1 ? "Sí" : "No")+" ")])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-link"},[_vm._v(" "+_vm._s(_vm.getFormatedDate(item.fecha))+" ")])]}},{key:"item.stepName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-step-document"},[_vm._v(" "+_vm._s(item.stepName)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icons-container-navigation"},[_c('a',{staticClass:"link-non-decoration",attrs:{"href":_vm.getLink(item.link),"target":"_blank","download":""}},[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.noAction()}}},[_vm._v(" visibility ")])]),(
              _vm.user.person.id === item.person_id ||
                _vm.user.person.types_person.id === 3
            )?_c('a',[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.editItemFile(item)}}},[_vm._v(" edit ")])]):_vm._e(),(
              _vm.user.person.id === item.person_id ||
                _vm.user.person.types_person.id === 3
            )?_c('a',[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.deleteItemFile(item.id)}}},[_vm._v(" delete_forever_outlined ")])]):_vm._e()])]}}],null,true)}),_c('LoaderEntity',{attrs:{"loading":_vm.loadingFileList}}),_c('SnackBarEntity',{attrs:{"colorSnack":_vm.colorSnackFileList,"snackbarMessage":_vm.snackbarMessageFileList,"snack":_vm.snackFileList},on:{"action":_vm.hideSnackBarFileList}}),(_vm.drawerFiles)?_c('DialogConfirm',{on:{"action":_vm.deleteFile}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }