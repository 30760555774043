<template lang="">
  <div>
    <v-row id="table-img-step" class="window-table-docs-step gallery-step" v-if="getImages.length">
      <v-col
        v-for="(item, index) in getImages"
        :key="index"
        class="gallery-image-container d-flex child-flex"
        cols="6"
        sm="6"
        md="6"
        lg="3"
      >
        <div>
          <a
            :href="getLink(item.link)"
            target="_blank"
            download
            class="link-non-decoration"
          >
          <v-img
            :src="getUrlImg(item.link)"
            aspect-ratio="1"
            class="grey lighten-2"
            elevation="1"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          </a>
          <v-row class="gallery-image-description no-gutters pt-2 pb-4">
            <v-col>
              {{ getNameImage(item.description) }}
              <span 
              v-if="item.description > charactersToShow"
              >
              
              </span>
            </v-col>
          </v-row>
          <v-row class="gallery-i-actions">
            <a
            v-if="
              user.person.id === item.person_id ||
                user.person.types_person.id === 3
            "
            >
            <v-btn
            x-small
            class="fvl-btn transparent-primary mr-1"
            elevation="0"
           @click="editItemImageStep(item)">
              <v-icon x-small>edit</v-icon>
            </v-btn>
            </a>
            <a
              v-if="
                user.person.id === item.person_id ||
                user.person.types_person.id === 3
              "
            >
              <v-btn
              x-small
              class="fvl-btn transparent-primary"
              elevation="0"
              @click="deleteItemImageStep(item.id)">
                   <v-icon x-small>delete</v-icon>
              </v-btn>
              </a>
           
          </v-row>
        </div>
      </v-col>
    </v-row>
     <v-row id="table-img-step" class="window-table-docs-step gallery-step" v-else>
      <span class="text-images-none">No hay imágenes cargadas en esta etapa.</span>
    </v-row>
    <LoaderEntity :loading="loadingImageStep" />
    <SnackBarEntity
      :colorSnack="colorSnackImageStep"
      :snackbarMessage="snackbarMessageImageStep"
      :snack="snackImageStep"
      @action="hideSnackBarImageStep"
    />
    <DialogConfirmEntity
      @action="deleteImageStep"
      @actionClose="closeModal"
      :showModal="dialogConfirmStep"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import {
  DialogConfirmEntity,
  LoaderEntity,
  SnackBarEntity,
} from "../../_commons";
import { setAllInFalse } from "../helpers/setAllInFalse";
export default {
  name: "StepImages",
  components: {
    DialogConfirmEntity,
    LoaderEntity,
    SnackBarEntity,
  },
  data() {
    return {
      loadingImageStep: false,
      snackImageStep: false,
      colorSnackImageStep: "",
      snackbarMessageImageStep: "",
      dialogConfirmStep: false,
      charactersToShow: 15,
    };
  },
  methods: {
    hideSnackBarImageStep() {
      this.snackImageStep = false;
    },
    closeModal() {
      this.dialogConfirmStep = false;
    },
    getLink(link) {
      const linkServer = `${process.env.VUE_APP_SERVER}${link}`;
      return linkServer;
    },
    getUrlImg(image) {
      const url = image
        ? `${process.env.VUE_APP_SERVER}${image}`
        : "../../assets/img/item1.png";
      return url;
    },
    getNameImage(description) {
      if (!description) return "";
      const extArray = description.split(".");
      const ext = extArray[extArray.length - 1];
      const descriptionToShow = `${description.substring(
        0,
        this.charactersToShow
      )}${description.length > this.charactersToShow ? ".." : ""}.${ext}`;
      return descriptionToShow;
    },
    async editItemImageStep(image) {
      await setAllInFalse();
      const imageToEdit = {
        ...image,
        stepDocumentId: this.stepSelected.step.id,
      };
      await this.$store.dispatch("setDocumentToEditAction", imageToEdit);
      await this.$store.dispatch("setDrawerUpdateDocumentAction", true);
    },
    async deleteItemImageStep(id) {
      const msg = `¿Quieres eliminar esta imagen?`;
      this.dialogConfirmStep = true;
      await this.$store.dispatch("setIdItemDialogAction", id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
    },
    async deleteImageStep() {
      this.loadingImageStep = true;
      this.closeModal();
      const { status } = await this.$store.dispatch(
        "deleteDocumentStep",
        this.ui.idItemDialog
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackImageStep = true;
        this.colorSnackImageStep = "success";
        this.snackbarMessageImageStep = "Documento eliminado correctamente";
        this.loadingImageStep = false;
      } else {
        this.snackImageStep = true;
        this.colorSnackImageStep = "danger";
        this.snackbarMessageImageStep = "Error, intente nuevamente";
        this.loadingImageStep = false;
      }
    },
  },
  computed: {
    ...mapState({
      stepSelected: (state) => state.projects.stepProjectsSelected,
      ui: (state) => state.ui,
      user: (state) => state.user,
    }),
    ...mapGetters(["getImages"]),
  },
};
</script>
