<template lang="">
  <div class="window-table-docs-step">
    <div class="title-description-step">Descripción</div>
    <div class="content-description-step">
      {{ stepSelected.step.description }}
    </div>
    <div
      class="title-description-step"
      v-if="project.project_rol.id_project_rol !== 3 || user.person.types_person.id===3"
    >
      Estado de la etapa
    </div>
    <div
      class="content-description-step"
      v-if="project.project_rol.id_project_rol !== 3 || user.person.types_person.id===3"
    >
      <div class="section-buttons-description">
        <v-autocomplete
          :items="getStatesStepsForSelect"
          v-model="newState"
          dense
          solo
          style="max-width: 250px!important"
          @change="editState()"
        ></v-autocomplete>
        <v-btn
          class="fvl-btn transparent-primary mt-2"
          elevation="1"
          small
          @click="editPublic()">
           <v-icon left small>{{ newIsPublic === 1 ? "public" : "security" }}</v-icon>
            {{ newIsPublic === 1 ? "Pública" : "Privada" }}
        </v-btn>
      </div>
    </div>
    <div v-else>
      <hr />
      <div class="mt-5">
        {{ getStateStepById(newState).description || "" }}
      </div>      
      <div>
        {{ newIsPublic === 1 ? "Público" : "Privado" }}
        <v-icon small>{{ newIsPublic === 1 ? "public" : "security" }}</v-icon>
      </div>      
    </div>
    <!-- <LoaderEntity :loading="loadingStepDescription" /> -->
    <SnackBarEntity
      :colorSnack="colorSnackStepDescription"
      :snackbarMessage="snackbarMessageStepDescription"
      :snack="snackStepDescription"
      @action="hideSnackBarStepDescription"
    />
    <DialogConfirmEntity
      @action="changeIsPublicStep"
      @actionClose="closeModalIsPublic"
      :showModal="dialogConfirmChangeIsPublic"
    />
    <DialogConfirmEntity
      @action="changeStateStep"
      @actionClose="closeModalState"
      :showModal="dialogConfirmChangeState"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import {
  DialogConfirmEntity,
  LoaderEntity,
  SnackBarEntity,
} from "../../_commons";
export default {
  name: "StepDescription",
  components: {
    DialogConfirmEntity,
    LoaderEntity,
    SnackBarEntity,
  },
  data() {
    return {
      loadingStepDescription: false,
      snackStepDescription: false,
      colorSnackStepDescription: "",
      snackbarMessageStepDescription: "",
      dialogConfirmChangeIsPublic: false,
      dialogConfirmChangeState: false,
      newState: "",
      newIsPublic: "",
    };
  },
  methods: {
    hideSnackBarStepDescription() {
      this.snackStepDescription = false;
    },
    closeModalIsPublic() {
      this.dialogConfirmChangeIsPublic = false;
      this.newIsPublic = this.stepSelected.step.is_public;
    },
    closeModalState() {
      this.dialogConfirmChangeState = false;
      this.newState = this.stepSelected.step.state;
    },
    async editPublic() {
      let msg = "";
      if (this.stepSelected.step.is_public === 0) {
        msg = `¿Quieres que esta etapa sea pública?`;
        this.newIsPublic = 1;
      } else {
        this.newIsPublic = 0;
        msg = `¿Quieres que esta etapa sea privada?`;
      }
      await this.$store.dispatch(
        "setIdItemDialogAction",
        this.stepSelected.step.id
      );
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      this.dialogConfirmChangeIsPublic = true;
    },
    async changeIsPublicStep() {
      this.loadingStepDescription = true;
      this.dialogConfirmChangeIsPublic = false;
      const formData = {
        is_public: this.newIsPublic,
        id_step_project: this.stepSelected.step.id,
      };
      const { status } = await this.$store.dispatch(
        "updatePublicProjectStep",
        formData
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackStepDescription = true;
        this.colorSnackStepDescription = "success";
        this.snackbarMessageStepDescription = "Etapa actualizada correctamente";
        this.loadingStepDescription = false;
      } else {
        this.snackStepDescription = true;
        this.colorSnackStepDescription = "danger";
        this.snackbarMessageStepDescription = "Error, intente nuevamente";
        this.loadingStepDescription = false;
      }
    },
    async editState() {
      let msg = `¿Deseas actualizar el estado de esta etapa?`;
      await this.$store.dispatch(
        "setIdItemDialogAction",
        this.stepSelected.step.id
      );
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      this.dialogConfirmChangeState = true;
    },
    async changeStateStep() {
      this.loadingStepDescription = true;
      this.dialogConfirmChangeState = false;
      const formData = {
        state: this.newState,
        id_step_project: this.stepSelected.step.id,
      };
      const { status } = await this.$store.dispatch(
        "updateStateProjectStep",
        formData
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackStepDescription = true;
        this.colorSnackStepDescription = "success";
        this.snackbarMessageStepDescription = "Etapa actualizada correctamente";
        this.loadingStepDescription = false;
      } else {
        this.snackStepDescription = true;
        this.colorSnackStepDescription = "danger";
        this.snackbarMessageStepDescription = "Error, intente nuevamente";
        this.loadingStepDescription = false;
      }
    },
  },
  computed: {
    ...mapState({
      stepSelected: (state) => state.projects.stepProjectsSelected,
      ui: (state) => state.ui,
      user: (state) => state.user,
      project: (state) => state.projects.projectSelected,
    }),
    ...mapGetters(["getStatesStepsForSelect", "getStateStepById"]),
  },
  watch: {
    stepSelected: {
      handler() {
        this.newState = this.stepSelected.step.state;
        this.newIsPublic = this.stepSelected.step.is_public;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang=""></style>
