<template lang="">
  <div class="container-project-detil">    
    <div v-if="project">
      <LoaderLinear />
      <div
        class="header-project"
        :style="{
        backgroundImage: 'url(' + getUrlImg(project.project.image) + ')',
        }"
      >
        <div class="header-project-data">
          <div class="title-img-project">{{ project.project.name }}</div>
          <div class="text-img-project">
             <div class="hashtag-type-container">
              <span
              class="hashtag-img-project"
              v-for="(type, indexType) in project.project_types"
              :key="indexType"
              >
                <v-icon style="font-size:16px;">stop</v-icon>{{ type.name }}
              </span>
            </div>
            <div>
              <p>{{ getDescription(project.project.description) }}</p>
              
            </div>
            <span>
                 <v-btn class="fvl-btn transparent-secundary mt-0 mb-2"
                 x-small 
                 elevation="1"
                 plain
                 @click="changeShowingAll"
                 >
                    {{ getShowMoreLess() }}
                </v-btn>
              </span>
          </div>
        </div>
        <div class="section-buttons-project">
           <v-btn class="fvl-btn green-primary" small elevation="1" @click="openUpload">
            <v-icon small>add</v-icon>Subir
          </v-btn>
          <v-btn class="fvl-btn green-military"  small  elevation="1" @click="openActivity">
            Actividad
          </v-btn> 
          <v-btn class="fvl-btn green-military"  small  elevation="1"  @click="openLinks">
            Links
          </v-btn>
          <v-btn class="fvl-btn green-military" small  elevation="1" @click="openFiles">
            Archivos
          </v-btn>
         
      </div>
      </div>
 
      <v-row id="section-slider-project" class="container-stepper" v-if="project.steps">
        <StepperProject />
      </v-row>
      <div id="section-step-detail" v-if="project.steps">
          <StepDetail />
          <LoaderEntity :loading="loadingStepDescription" />
      </div>            
      <NavigationDrawerActivity />       
      <NavigationDrawerLink />      
      <NavigationDrawerFiles />      
      <NavigationDrawerUploadFile />
      <NavigationDrawerUpdateDocument />
      <NavigationDrawerNewProduct />
      <SnackBar />
    </div>
    <Overlay />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import {
  BreadCrumbs,
  LoaderLinear,
  SnackBar,
  Overlay,
} from "../../components/_commons";
import StepperProject from "../../components/project/StepperProject.vue";
import StepDetail from "../../components/project/stepDetail/StepDetail.vue";
import NavigationDrawerActivity from "../../components/project/navigation-drawers/NavigationDrawerActivity.vue";
import NavigationDrawerLink from "../../components/project/navigation-drawers/NavigationDrawerLink.vue";
import NavigationDrawerFiles from "../../components/project/navigation-drawers/NavigationDrawerFiles.vue";
import NavigationDrawerUploadFile from "../../components/project/navigation-drawers/NavigationDrawerUploadFile.vue";
import NavigationDrawerNewProduct from "../../components/project/navigation-drawers/NavigationDrawerNewProduct.vue";
import NavigationDrawerUpdateDocument from "../../components/project/navigation-drawers/NavigationDrawerUpdateDocument.vue";
import { setAllInFalse } from "../../components/project/helpers/setAllInFalse";
export default {
  name: "StepEdit",
  components: {
    BreadCrumbs,
    LoaderLinear,
    SnackBar,
    Overlay,
    StepperProject,
    StepDetail,
    NavigationDrawerActivity,
    NavigationDrawerLink,
    NavigationDrawerFiles,
    NavigationDrawerUploadFile,
    NavigationDrawerNewProduct,
    NavigationDrawerUpdateDocument,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.projectDetail,
      charactersToShow: 100,
      showingAllDescription: false,
    };
  },
  computed: {
    ...mapState({
      project: (state) => state.projects.projectSelected,
      user: (state) => state.user,
    }),
  },
  methods: {
    getUrlImg(image) {
      const url = image
        ? `${process.env.VUE_APP_SERVER}${image}`
        : "../../assets/img/item1.png";
      return url;
    },
    async openActivity() {
      await setAllInFalse();
      await this.$store.dispatch("setDrawerActivityAction", true);
    },
    async openLinks() {
      await setAllInFalse();
      await this.$store.dispatch("setDrawerLinkAction", true);
    },
    async openFiles() {
      await setAllInFalse();
      await this.$store.dispatch("setDrawerFilesAction", true);
    },
    async openUpload() {
      await setAllInFalse();
      await this.$store.dispatch("setDrawerUploadAction", true);
    },
    getDescription(description) {
      if (this.showingAllDescription) {
        return description;
      }
      const descriptionToShow = `${description.substring(
        0,
        this.charactersToShow
      )}${description.length > this.charactersToShow ? "..." : ""}`;
      return descriptionToShow;
    },
    getShowMoreLess() {
      return this.showingAllDescription ? "menos" : "Ver más";
    },
    changeShowingAll() {
      this.showingAllDescription = !this.showingAllDescription;
    },
  },
  async created() {
    await beginRequest();
    await this.$store.dispatch("setStepProjectelectedAction", null);
    await this.$store.dispatch("setProjectSelectedAction", null);
    await this.$store.dispatch("removeDocumentsSelectedAction");
    await this.$store.dispatch("setProductsAction");
    await this.$store.dispatch("setStatesToStepAction");
    const payload={
      idProject:this.$route.params.idproject,
      idperson:this.user.person.id
    };
    const { snack, status } = await this.$store.dispatch(
      "getProjectDetailById",
      payload
    );
    await endRequest(snack, status);
  },
};
</script>
