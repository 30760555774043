<template>
  <v-navigation-drawer
    fixed
    right
    :value="drawerNewProduct"
    class="navigation-projects"
  >
  <!-- // Header Links -->
  <v-row id="nav-drawer-header" class="ndh-solid">
      <div>
        <span class="span-title">{{ project.project.name }} / </span>
        <span class="span-subtitle">Añadir Producto</span>
      </div>
      <div>
        <button icon class="btn-close-drawer" primary  @click.stop="closedrawerNewProduct">
          <v-icon>menu_open</v-icon>
        </button>
      </div>
    </v-row>

    <form id="form-update-docs" @submit.prevent="sendFormProduct">
      <v-row>
       
          <v-autocomplete
            class = "fvl-small-icons"
            prepend-icon="inventory"
            clearable
            v-model="$v.productSelected.$model"
            :items="getProductsForSelect"
            placeholder="Selecciona un producto"
            :error-messages="productErrors"
          ></v-autocomplete>
        
      </v-row>
      <v-row>
        
          <v-text-field
            class = "fvl-small-icons"
            prepend-icon="add_circle"
            v-model="$v.quantity.$model"
            placeholder="Cantidad"
            type="number"
            :error-messages="quantityErrors"
          ></v-text-field>
        
      </v-row>
      <v-row>
      
          <v-autocomplete
            prepend-icon="stop"
            clearable
            v-model="$v.stepSelected.$model"
            :items="getProjectStepsForSelect"
            placeholder="Selecciona una etapa"
            :error-messages="stepErrors"
          ></v-autocomplete>
       
        </v-row>
     <div id="nd-commands" class="container-commands-upload-file">
      <v-row  class=" fvl-w-100 d-flex justify-end">
          <v-btn
            class="fvl-btn green-primary"
            elevation="1"
            type="submit"
            :disabled="$v.$invalid"
          >
            <v-icon left small>add_circle</v-icon> Añadir
           
          </v-btn>
      </v-row>
      </div>
    </form>    
    <LoaderEntity :loading="loadingProductForm" />
    <SnackBarEntity
      :colorSnack="colorSnackProductForm"
      :snackbarMessage="snackbarMessageProductForm"
      :snack="snackProductForm"
      @action="hideSnackBarProductForm"
    />
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { LoaderEntity, SnackBarEntity } from "../../_commons";
export default {
  name: "NavigationDrawerNewProduct",
  mixins: [validationMixin],
  validations: {
    quantity: { required },
    stepSelected: { required },
    productSelected: { required },
  },
  components: {
    LoaderEntity,
    SnackBarEntity,
  },
  data: () => ({
    stepSelected: "",
    productSelected: "",
    quantity: "",
    loadingProductForm: false,
    snackProductForm: false,
    colorSnackProductForm: "",
    snackbarMessageProductForm: "",
  }),
  computed: {
    ...mapState({
      drawerNewProduct: (state) => state.projects.drawerNewProduct,
      project: (state) => state.projects.projectSelected,
      products: (state) => state.products.products,
      user: (state) => state.user,
    }),
    ...mapGetters(["getProjectStepsForSelect", "getProductsForSelect"]),
    quantityErrors() {
      const errors = [];
      if (!this.$v.quantity.$dirty) return errors;
      !this.$v.quantity.required && errors.push("La cantidad es obligatoria");
      return errors;
    },
    stepErrors() {
      const errors = [];
      if (!this.$v.stepSelected.$dirty) return errors;
      !this.$v.stepSelected.required && errors.push("La etapa es obligatoria");
      return errors;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.productSelected.$dirty) return errors;
      !this.$v.productSelected.required &&
        errors.push("Debe seleccionar un prodcuto");
      return errors;
    },
  },
  methods: {
    hideSnackBarProductForm() {
      this.snackProductForm = false;
    },
    closedrawerNewProduct() {
      this.$store.dispatch("setDrawerNewProductAction", false);
    },
    async sendFormProduct() {
      this.loadingProductForm = true;
      const formData={
        product_id:this.productSelected,
        project_step_id:this.stepSelected,
        quantity:this.quantity,
      }
      const { status } = await this.$store.dispatch(
        "createProductStep",
        formData
      );

      if (status >= 200 && status < 300) {
        //si se cargo correctamente buscamos proyecto
        this.stepSelected = 0;
        this.productSelected = 0;
        this.quantity = "";
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackProductForm = true;
        this.colorSnackProductForm = "success";
        this.snackbarMessageProductForm = "Producto cargado correctamente";
        this.loadingProductForm = false;
      } else {
        this.snackProductForm = true;
        this.colorSnackProductForm = "danger";
        this.snackbarMessageProductForm = "Error, intente nuevamente";
        this.loadingProductForm = false;
      }
      this.$v.$reset();
    },
  },
  created() {
    this.loadingProductForm = false;
    this.snackFileloadingProductForm = false;
  },
};
</script>
<style lang=""></style>
