<template lang="">
  <div class="window-table-docs-step">
    <v-card-title>
      <v-text-field
        v-model="searchDocs"
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table :headers="docsheaders" :items="getDocs" :search="searchDocs">
      <template v-slot:[`item.name`]="{ item }">
        <v-icon small class="mr-2" @click="seeItem(item)">
          {{
            item.name.toLowerCase().includes("pdf")
              ? "picture_as_pdf"
              : "description"
          }}
        </v-icon>
        {{ item.name }}
      </template>
      <template v-slot:[`item.is_public`]="{ item }">
        <div>
          {{ item.is_public == 1 ? "Públ." : "Priv." }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="icons-container-step-document">
          <a
            :href="getLink(item.link)"
            target="_blank"
            download
            class="link-non-decoration"
          >
            <i id="fvl-i-w" class="material-icons" @click="noAction()">
                visibility
              </i>
          </a>
          <a
            v-if="
              user.person.id === item.person_id ||
                user.person.types_person.id === 3
            "
          >
           <i id="fvl-i-w" class="material-icons" @click="editItemFileStep(item)">
                edit
              </i>
          </a>
          <a
            v-if="
              user.person.id === item.person_id ||
                user.person.types_person.id === 3
            "
          >
             <i id="fvl-i-w" class="material-icons"  @click="deleteItemFileStep(item.id)">
                delete_forever_outlined
              </i>
          </a>
        </div>
         <LoaderEntity :loading="loadingFileStep" />
      </template>
    </v-data-table>
   
    <SnackBarEntity
      :colorSnack="colorSnackFileStep"
      :snackbarMessage="snackbarMessageFileStep"
      :snack="snackFileStep"
      @action="hideSnackBarFileStep"
    />
    <DialogConfirmEntity
      @action="deleteFileStep"
      @actionClose="closeModal"
      :showModal="dialogConfirmStep"
    />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { docsheaders } from "./headerDatatables.js";
import {
  DialogConfirmEntity,
  LoaderEntity,
  SnackBarEntity,
} from "../../_commons";
import { setAllInFalse } from "../helpers/setAllInFalse";
export default {
  name: "StepFiles",
  components: {
    DialogConfirmEntity,
    LoaderEntity,
    SnackBarEntity,
  },
  data() {
    return {
      tab: null,
      searchDocs: "",
      docsheaders,
      loadingFileStep: false,
      snackFileStep: false,
      colorSnackFileStep: "",
      snackbarMessageFileStep: "",
      dialogConfirmStep: false,
    };
  },
  methods: {
    hideSnackBarFileStep() {
      this.snackFileStep = false;
    },
    closeModal() {
      this.dialogConfirmStep = false;
    },
    getLink(link) {
      const linkServer = `${process.env.VUE_APP_SERVER}${link}`;
      return linkServer;
    },
    async editItemFileStep(document) {
      await setAllInFalse();
      const documentToEdit = {
        ...document,
        stepDocumentId: this.stepSelected.step.id,
      };
      await this.$store.dispatch("setDocumentToEditAction", documentToEdit);
      await this.$store.dispatch("setDrawerUpdateDocumentAction", true);
    },
    async deleteItemFileStep(id) {
      const msg = `¿Esta seguro que desea eliminar el archivo?`;
      this.dialogConfirmStep = true;
      await this.$store.dispatch("setIdItemDialogAction", id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
    },
    async deleteFileStep() {
      this.loadingFileStep = true;
      this.closeModal();
      const { status } = await this.$store.dispatch(
        "deleteDocumentStep",
        this.ui.idItemDialog
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackFileStep = true;
        this.colorSnackFileStep = "success";
        this.snackbarMessageFileStep = "Documento eliminado correctamente";
        this.loadingFileStep = false;
      } else {
        this.snackFileStep = true;
        this.colorSnackFileStep = "danger";
        this.snackbarMessageFileStep = "Error, intente nuevamente";
        this.loadingFileStep = false;
      }
    },
  },
  computed: {
    ...mapState({
      stepSelected: (state) => state.projects.stepProjectsSelected,
      ui: (state) => state.ui,
      user: (state) => state.user,
    }),
    ...mapGetters(["getDocs"]),
  },
};
</script>
