<template>
  <v-navigation-drawer
    fixed
    right
    :value="drawerUpload"
    class="navigation-projects"
  >

  <!-- // Header Links -->
  <v-row id="nav-drawer-header" class="ndh-solid">
      <div>
        <span class="span-title">{{ project.project.name }} / </span>
        <span class="span-subtitle">Agregar Archivo</span>
      </div>
      <div>
        <button icon class="btn-close-drawer" primary  @click.stop="closedrawerUpload">
          <v-icon>menu_open</v-icon>
        </button>
      </div>
    </v-row>

    <form  id="form-update-docs" @submit.prevent="sendFormFile">
      <v-row class="fvl-w-100 dense no-gutters justify-space-between">
    
          <v-file-input d-flex flex-row-reverse
            v-model="$v.file.$model"
            chips
            prepend-icon="attach_file"
            dense
            show-size
            truncate-length="15"
            placeholder="Adjuntar un archivo"
            :error-messages="fileErrors"
          ></v-file-input>
      </v-row>
      <v-row class="fvl-w-100 d-flex justify-space-between">
       
          <v-autocomplete
            prepend-icon="stop"
            v-model="$v.stepSelected.$model"
            :items="getStepsForSelectWithProject"
            placeholder="Selecciona el proyecto o una etapa"
            :error-messages="stepErrors"
          ></v-autocomplete>

      </v-row>
      <v-row class="fvl-checkbox fvl-w-100 d-flex justify-space-between">
          <v-checkbox
            v-model="isPublic"
            label="Público"
            :disabled="
              project.project_rol.id_project_rol === 3 &&
                user.person.types_person.id !== 3
            "
          ></v-checkbox>

          <v-checkbox
            v-model="isPublicVisor"
            label="Mostrar en Visor"
            :disabled="
              project.project_rol.id_project_rol === 3 &&
                user.person.types_person.id !== 3
            "
          ></v-checkbox>

      </v-row>

      <div id="nd-commands" class="container-commands-upload-file">
      <v-row  class=" fvl-w-100 d-flex justify-end">
          <v-btn
            class="fvl-btn green-primary"
            elevation="1"
            type="submit"
            :disabled="$v.$invalid"
          >
            <v-icon left small>file_upload</v-icon>
             Subir
          </v-btn>
      </v-row>
      </div>
    </form>
    <LoaderEntity :loading="loadingFileForm" />
    <SnackBarEntity
      :colorSnack="colorSnackFileForm"
      :snackbarMessage="snackbarMessageFileForm"
      :snack="snackFileForm"
      @action="hideSnackBarFileForm"
    />
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { LoaderEntity, SnackBarEntity } from "../../_commons";
export default {
  name: "NavigationDrawerdrawerUpload",
  mixins: [validationMixin],
  validations: {
    file: { required },
    stepSelected: { required },
  },
  components: {
    LoaderEntity,
    SnackBarEntity,
  },
  data: () => ({
    file: null,
    stepSelected: 0,
    isPublic: false,
    isPublicVisor: false,
    loadingFileForm: false,
    snackFileForm: false,
    colorSnackFileForm: "",
    snackbarMessageFileForm: "",
  }),
  computed: {
    ...mapState({
      drawerUpload: (state) => state.projects.drawerUpload,
      project: (state) => state.projects.projectSelected,
      user: (state) => state.user,
    }),
    ...mapGetters(["getStepsForSelectWithProject"]),
    fileErrors() {
      const errors = [];
      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.required && errors.push("El archivo es requerido");
      return errors;
    },
    stepErrors() {
      const errors = [];
      if (!this.$v.stepSelected.$dirty) return errors;
      !this.$v.stepSelected.required &&
        errors.push("Debe seleccionar una opción");
      return errors;
    },
  },
  methods: {
    hideSnackBarFileForm() {
      this.snackFileForm = false;
    },
    closedrawerUpload() {
      this.$store.dispatch("setDrawerUploadAction", false);
    },
    async sendFormFile() {
      this.loadingFileForm = true;
      let formData = new FormData();
      let finalStatus = false;
      const is_public =
        this.project.project_rol.id_project_rol === 3 &&
        this.user.person.types_person.id !== 3
          ? 1
          : this.isPublic
          ? 1
          : 0;
      const is_public_visor =
        this.project.project_rol.id_project_rol === 3 &&
        this.user.person.types_person.id !== 3
          ? 1
          : this.isPublicVisor
          ? 1
          : 0;
      formData.append("description", this.file.name);
      formData.append("is_public", is_public);
      formData.append("is_public_visor", is_public_visor);
      formData.append("document", this.file);
      formData.append("id_project", this.project.project.id);
      formData.append("person_id", this.user.person.id);
      if (this.stepSelected === 0) {
        const { status } = await this.$store.dispatch(
          "createDocument",
          formData
        );
        finalStatus = status;
      } else {
        formData.append("id_project_step", this.stepSelected);
        const { status } = await this.$store.dispatch(
          "createDocumentStep",
          formData
        );
        finalStatus = status;
      }
      if (finalStatus >= 200 && finalStatus < 300) {
        //si se cargo correctamente buscamos proyecto
        this.file = null;
        this.isPublicVisor = false;
        this.isPublic = false;
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackFileForm = true;
        this.colorSnackFileForm = "success";
        this.snackbarMessageFileForm = "Archivo cargado correctamente";
        this.loadingFileForm = false;
      } else {
        this.snackFileForm = true;
        this.colorSnackFileForm = "danger";
        this.snackbarMessageFileForm = "Error, intente nuevamente";
        this.loadingFileForm = false;
      }
      this.$v.$reset();
    },
  },
  created() {
    this.loadingFileForm = false;
    this.snackFileloadingFileForm = false;
    this.isPublic =
      this.project.project_rol.id_project_rol === 3 &&
      this.user.person.types_person.id !== 3
        ? 1
        : 0;
    this.isPublicVisor =
      this.project.project_rol.id_project_rol === 3 &&
      this.user.person.types_person.id !== 3
        ? 1
        : 0;
  },
};
</script>
<style lang=""></style>
