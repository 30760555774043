<template lang="">
  <div  class="window-table-docs-step">
    <v-card-title>
      <v-text-field
        v-model="searchProd"
        append-icon="mdi-magnify"
        label="Buscar un producto..."
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-card-title>
     <v-row
      v-if="
        project.project_rol.id_project_rol !== 3 ||
          user.person.types_person.id === 3
      "
      
    >
      <v-btn
       small
       class="fvl-btn green-military"
       elevation="1"
       @click="openNewProduct">
        <v-icon left small>add_circle</v-icon>Añadir
      </v-btn>
    </v-row>
    <v-data-table
      id="products-step-data-table" 
      :headers="productsheaders"
      :items="stepSelected.products"
      :search="searchProd"
    >
      <template v-slot:[`item.date`]="{ item }">
        <div class="text-link">
          {{ getFormatedDate(item.date) }}
        </div>
      </template>
      <template
        v-slot:[`item.actions`]="{ item }"
        v-if="
          project.project_rol.id_project_rol !== 3 ||
            user.person.types_person.id === 3
        "
      >
        <v-icon small @click="deleteProductStep(item.id)">
          delete
        </v-icon>
      </template>
    </v-data-table>
    <SnackBarEntity
      :colorSnack="colorSnackProductsStep"
      :snackbarMessage="snackbarMessageProductsStep"
      :snack="snackProductsStep"
      @action="hideSnackBarProductsStep"
    />
    <DialogConfirmEntity
      @action="deleteProduct"
      @actionClose="closeModal"
      :showModal="dialogConfirmProductStep"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { productsheaders } from "./headerDatatables.js";
import { setAllInFalse } from "../helpers/setAllInFalse";
import {
  DialogConfirmEntity,
  LoaderEntity,
  SnackBarEntity,
} from "../../_commons";
export default {
  name: "StepDetail",
  components: {
    DialogConfirmEntity,
    LoaderEntity,
    SnackBarEntity,
  },
  data() {
    return {
      searchProd: "",
      productsheaders,
      loadingProductsStep: false,
      snackProductsStep: false,
      colorSnackProductsStep: "",
      snackbarMessageProductsStep: "",
      dialogConfirmProductStep: false,
    };
  },
  methods: {
    hideSnackBarProductsStep() {
      this.snackProductsStep = false;
    },
    closeModal() {
      this.dialogConfirmProductStep = false;
    },

    async openNewProduct() {
      await setAllInFalse();
      await this.$store.dispatch("setDrawerNewProductAction", true);
    },
    getFormatedDate(date) {
      const dateFormated = moment(date).format("DD/MM/YYYY");
      return dateFormated;
    },
    async deleteProductStep(id) {
      const msg = `¿Esta seguro que desea eliminar el producto del proyecto?`;
      this.dialogConfirmProductStep = true;
      await this.$store.dispatch("setIdItemDialogAction", id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
    },
    async deleteProduct() {
      this.loadingProductsStep = true;
      this.closeModal();
      const { status } = await this.$store.dispatch(
        "deleteProductStep",
        this.ui.idItemDialog
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackProductsStep = true;
        this.colorSnackProductsStep = "success";
        this.snackbarMessageProductsStep = "Documento eliminado correctamente";
        this.loadingProductsStep = false;
      } else {
        this.snackProductsStep = true;
        this.colorSnackProductsStep = "danger";
        this.snackbarMessageProductsStep = "Error, intente nuevamente";
        this.loadingProductsStep = false;
      }
    },
  },
  computed: {
    ...mapState({
      stepSelected: (state) => state.projects.stepProjectsSelected,
      ui: (state) => state.ui,
      user: (state) => state.user,
      project: (state) => state.projects.projectSelected,
    }),
  },
};
</script>
