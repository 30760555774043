<template lang="">
  <v-row class="container-step-detail" v-if="stepSelected">
    <template>
      <v-tabs v-model="tab">
        <v-tab v-for="(tab, i) in items" :key="tab.item" class="tab-project">
          {{ i === 0 ? stepSelected.step.name : tab.name }}
        </v-tab>
      </v-tabs>
    </template>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(content, index) in items" :key="content.item">
        <v-card flat v-if="content.item === 0">
          <StepDescription />
        </v-card>
        <v-card flat v-if="content.item === 1">
          <StepFiles />
        </v-card>
        <v-card flat v-if="content.item === 3">
          <StepImages />
        </v-card>
        <v-card flat v-if="content.item === 4">
          <StepProducts />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import StepFiles from "./StepFiles.vue";
import StepImages from "./StepImages.vue";
import StepProducts from "./StepProducts.vue";
import StepDescription from "./StepDescription.vue";
export default {
  name: "StepDetail",
  components: {
    StepImages,
    StepFiles,
    StepProducts,
    StepDescription
  },
  data() {
    return {
      tab: null,
      charactersToShow: 10,
      items: [
        { item: 0, name: "Descripción" },
        { item: 1, name: "Docs" },
        { item: 3, name: "Galería" },
        { item: 4, name: "Productos" },
      ],
    };
  },
  computed: {
    ...mapState({
      stepSelected: (state) => state.projects.stepProjectsSelected,
    }),
  },
};
</script>
