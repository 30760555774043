<template>
  <v-navigation-drawer
    fixed
    right
    :value="drawerLink"
    class="navigation-projects"
  >
    <!-- // Header Links -->
    <v-row id="nav-drawer-header"  class="ndh-solid">
      <div>
        <span class="span-title">{{ project.project.name }} / </span>
        <span class="span-subtitle">Links</span>
      </div>
      <div>
        <button icon class="btn-close-drawer" primary @click.stop="closeLink">
          <v-icon>menu_open</v-icon>
        </button>
      </div>
    </v-row>
    <!-- // Header Links -->
    <div >
      <div id="container-search-drawer">
        <v-text-field
          v-model="search"
          label="Buscar"
          outlined
          dense
          class="mx-4"
        ></v-text-field>
      </div>
    </div>
    <div id="nd-container" v-if="!addNewLink">
      <v-data-table
        :headers="headers"
        :items="project.links"
        item-key="name"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        hide-default-header
        hide-default-footer
      >
        <template v-slot:top> </template>
        <template v-slot:[`item.is_public`]="{ item }">
          <div class="text-link">
            {{ item.is_public === 1 ? "Público" : "Privado" }}
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <a :href="item.link" target="_blank" class="container-link">
            <div class="badge-link">
              <v-icon small color="primary">insert_link</v-icon>
              {{ item.name }}
            </div>
          </a>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="icons-container-navigation">
            <div>
              <a
                v-if="
                  user.person.id === item.person_id ||
                    user.person.types_person.id === 3
                "
              >
                <i
                  id="fvl-i-w"
                  class="material-icons"
                  @click="deleteItem(item.id)"
                  >delete_forever_outlined
                </i>
              </a>
            </div>
          </div>
        </template>
      </v-data-table>
      <LoaderEntity :loading="loadingLink" />
      <SnackBarEntity
        :colorSnack="colorSnackLink"
        :snackbarMessage="snackbarMessageLink"
        :snack="snackLink"
        @action="hideSnackBarLink"
      />
      <DialogConfirm @action="deleteLink" v-if="drawerLink" />
    </div>
    <div v-else>
      <LinkForm @action="cancelAddLink" />
    </div>
    <div id="nd-commands">
      <!-- // Button Add Link -->
      <v-row class="fvl-w-100 d-flex justify-end">
         <v-btn class="fvl-btn green-primary" elevation="1" @click="addLink">
           <v-icon left>add_link</v-icon>Añadir link
          </v-btn>
      </v-row>
      <!-- // Button Add Link -->
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
import LinkForm from "../../project/links/LinkForm.vue";
import { DialogConfirm, LoaderEntity, SnackBarEntity } from "../../_commons";
export default {
  name: "NavigationDrawerLink",
  components: {
    LinkForm,
    DialogConfirm,
    LoaderEntity,
    SnackBarEntity,
  },
  data() {
    return {
      search: "",
      addNewLink: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "name",
        },
        {
          text: "Tipo",
          align: "start",
          value: "is_public",
        },
        { text: "Acciones", align: "end", value: "actions", sortable: false },
      ],
      loadingLink: false,
      snackLink: false,
      colorSnackLink: "",
      snackbarMessageLink: "",
    };
  },
  computed: {
    ...mapState({
      drawerLink: (state) => state.projects.drawerLink,
      project: (state) => state.projects.projectSelected,
      ui: (state) => state.ui,
      user: (state) => state.user,
    }),
  },
  methods: {
    hideSnackBarLink() {
      this.snackLink = false;
    },
    closeLink() {
      this.addNewLink = false;
      this.$store.dispatch("setDrawerLinkAction", false);
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    addLink() {
      this.addNewLink = true;
    },
    cancelAddLink() {
      this.addNewLink = false;
    },
    async deleteItem(id) {
      const msg = `¿Esta seguro que desea eliminar el link?`;
      await this.$store.dispatch("setIdItemDialogAction", id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      await this.$store.dispatch("setDialogShowAction");
    },
    async deleteLink() {
      this.loadingLink = true;
      await this.$store.dispatch("setDialogShowAction");
      const { status } = await this.$store.dispatch(
        "deleteLink",
        this.ui.idItemDialog
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackLink = true;
        this.colorSnackLink = "success";
        this.snackbarMessageLink = "Link eliminado correctamente";
        this.loadingLink = false;
      } else {
        this.snackLink = true;
        this.colorSnackLink = "danger";
        this.snackbarMessageLink = "Error, intente nuevamente";
        this.loadingLink = false;
      }
    },
  },
  created() {
    this.loadingLink = false;
    this.snackLink = false;
  },
};
</script>
<style lang=""></style>
