<template>
  <v-navigation-drawer
    fixed
    right
    :value="drawerFiles"
    id="nav-drawer-files"
    class="navigation-projects"
  >
   <!-- // Header Links -->
    <v-row id="nav-drawer-header" class="ndh-solid">
      <div>
        <span class="span-title">{{ project.project.name }} / </span>
        <span class="span-subtitle">Todos los Archivos</span>
      </div>
      <div>
        <button icon class="btn-close-drawer" primary @click.stop="closeFiles">
          <v-icon>menu_open</v-icon>
        </button>
      </div>
    </v-row>
    <!-- // Header Links -->
    <!-- // Table -->
    <div id="container-table-drawler">
      <v-data-table
        :headers="headers"
        :items="getAllDocuments"
        item-key="name"
        :search="search"
        :custom-filter="filterOnlyCapsText"
      >
        <template v-slot:top>
      
          <v-text-field
            v-model="search"
            label="Buscar"
            outlined
            dense
            class="container-search-files"
          ></v-text-field>
  
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <a
            :href="getLink(item.link)"
            target="_blank"
            download
            class="link-non-decoration"
          >
            {{ item.name }}
          </a>
        </template>
        <template v-slot:[`item.is_public`]="{ item }">
          <div class="text-link">
            {{ item.is_public == 1 ? "Públ." : "Priv." }}
          </div>
        </template>
        <template v-slot:[`item.is_public_visor`]="{ item }">
          <div class="text-link">
            {{ item.is_public_visor == 1 ? "Sí" : "No" }}
          </div>
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          <div class="text-link">
            {{ getFormatedDate(item.fecha) }}
          </div>
        </template>
        <template v-slot:[`item.stepName`]="{ item }">
          <div class="text-step-document">
            {{ item.stepName }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="icons-container-navigation">
            <a
              :href="getLink(item.link)"
              target="_blank"
              download
              class="link-non-decoration"
            >
              <i id="fvl-i-w" class="material-icons" @click="noAction()">
                visibility
              </i>
            </a>
            <a
              v-if="
                user.person.id === item.person_id ||
                  user.person.types_person.id === 3
              "
            >
              <i id="fvl-i-w" class="material-icons" @click="editItemFile(item)">
                edit
              </i>
            </a>
            <a
              v-if="
                user.person.id === item.person_id ||
                  user.person.types_person.id === 3
              "
            >
              <i id="fvl-i-w" class="material-icons" @click="deleteItemFile(item.id)">
                delete_forever_outlined
              </i>
            </a>
          </div>
        </template>
      </v-data-table>
      <LoaderEntity :loading="loadingFileList" />
      <SnackBarEntity
        :colorSnack="colorSnackFileList"
        :snackbarMessage="snackbarMessageFileList"
        :snack="snackFileList"
        @action="hideSnackBarFileList"
      />
      <DialogConfirm @action="deleteFile" v-if="drawerFiles" />
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { DialogConfirm, LoaderEntity, SnackBarEntity } from "../../_commons";
import { setAllInFalse } from "../helpers/setAllInFalse";
export default {
  name: "NavigationDrawerFiles",
  components: {
    DialogConfirm,
    LoaderEntity,
    SnackBarEntity,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "description",
          width: "280px",
          class:"file-name-table",
        },
        {
          text: "Alc.",
          align: "start",
          value: "is_public",
          width: "60px",
          class:"file-name-table",
        },
        {
          text: "Visor",
          align: "start",
          value: "is_public_visor",
          width: "70px",
          class:"file-name-table",
        },
        {
          text: "Fecha",
          align: "start",
          value: "fecha",
          class:"file-name-table",
          width: "100px",
        },
        {
          text: "Etapa",
          align: "start",
          value: "stepName",
          width: "100px",
          class:"file-name-table",
        },
        { text: "Acciones", 
          align: "start", 
          value: "actions", 
          sortable: false,
          class:"file-name-table",
          },
      ],
      loadingFileList: false,
      snackFileList: false,
      colorSnackFileList: "",
      snackbarMessageFileList: "",
    };
  },
  computed: {
    ...mapState({
      drawerFiles: (state) => state.projects.drawerFiles,
      project: (state) => state.projects.projectSelected,
      user: (state) => state.user,
      ui: (state) => state.ui,
    }),
    ...mapGetters(["getAllDocuments"]),
  },
  methods: {
    hideSnackBarFileList() {
      this.snackFileList = false;
    },
    closeFiles() {
      this.$store.dispatch("setDrawerFilesAction", false);
    },
    noAction() {},
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    getLink(link) {
      const linkServer = `${process.env.VUE_APP_SERVER}${link}`;
      return linkServer;
    },
    getFormatedDate(date) {
      const dateFormated = moment(date).format("DD/MM/YYYY");
      return dateFormated;
    },
    async editItemFile(document) {
      await setAllInFalse();
      const docToEdit = {
        ...document,
        stepDocumentId: document.stepId,
      };
      await this.$store.dispatch("setDocumentToEditAction", docToEdit);
      await this.$store.dispatch("setDrawerUpdateDocumentAction", true);
    },
    async deleteItemFile(id) {
      const msg = `¿Esta seguro que desea eliminar el archivo?`;
      await this.$store.dispatch("setIdItemDialogAction", id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      await this.$store.dispatch("setDialogShowAction");
    },
    async deleteFile() {
      this.loadingFileList = true;
      await this.$store.dispatch("setDialogShowAction");
      const { status } = await this.$store.dispatch(
        "deleteFile",
        this.ui.idItemDialog
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackFileList = true;
        this.colorSnackFileList = "success";
        this.snackbarMessageFileList = "Archivo eliminado correctamente";
        this.loadingFileList = false;
      } else {
        this.snackFileList = true;
        this.colorSnackFileList = "danger";
        this.snackbarMessageFileList = "Error, intente nuevamente";
        this.loadingFileList = false;
      }
    },
  },
  created() {
    this.loadingFileList = false;
    this.snackFileloadingFileList = false;
  },
};
</script>
<style lang=""></style>
