<template lang="">
  <div class="container-form-links">
   
    <form @submit.prevent="sendFormLink">
      <div>
        <v-text-field
          v-model="$v.link.$model"
          outlined
          dense
          label="Introduce una URL (*)"
          :error-messages="linkErrors"
          @blur="$v.link.$touch()"
        >
        </v-text-field>
      </div>
      <div>
        <v-text-field
          v-model="$v.name.$model"
          outlined
          dense
          label="Introduce un nombre para el vínculo (*)"
          :error-messages="nameErrors"
          @blur="$v.name.$touch()"
        >
        </v-text-field>
      </div>
      <div class="fvl-checkbox">
        <v-checkbox
          v-model="isPublic"
          label="Público"
          :disabled="
            project.project_rol.id_project_rol === 3 &&
              user.person.types_person.id !== 3
          "
        ></v-checkbox>
      </div>
      <div id="nd-commands">
        <v-row class="fvl-w-100 d-flex justify-space-between">
           <v-btn class="fvl-btn transparent-primary" elevation="1" @click.stop="goToBack">
           Atrás
          </v-btn>
         
          <v-btn
            class="fvl-btn green-primary"
            elevation="1"
            type="submit"
            :disabled="$v.$invalid"
          >
            <v-icon left>add_link</v-icon>Añadir Link
          </v-btn>
        </v-row>
      </div>
      <v-overlay absolute :value="loadingLinkForm">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-snackbar
        v-model="snackLinkForm"
        multi-line
        :color="colorSnackLinkForm"
      >
        {{ snackbarMessageLinkForm }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="hideSnackBarLinkForm"
          >
            <v-icon> close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "FormLink",
  mixins: [validationMixin],
  validations: {
    name: { required, minLength: minLength(3) },
    link: { required, minLength: minLength(6) },
  },
  data() {
    return {
      name: "",
      link: "",
      isPublic: false,
      loadingLinkForm: false,
      snackLinkForm: false,
      colorSnackLinkForm: "",
      snackbarMessageLinkForm: "",
    };
  },
  computed: {
    ...mapState({
      project: (state) => state.projects.projectSelected,
      user: (state) => state.user,
    }),
    linkErrors() {
      const errors = [];
      if (!this.$v.link.$dirty) return errors;
      !this.$v.link.minLength &&
        errors.push("La longitud minima es de 6 caracteres");
      !this.$v.link.required && errors.push("El link es requerido");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.name.required && errors.push("El nombre es requerido");
      return errors;
    },
  },
  methods: {
    hideSnackBarLinkForm() {
      this.snackLinkForm = false;
    },
    async sendFormLink() {
      this.loadingLinkForm = true;
      const formData = {
        name: this.name,
        link: this.link,
        is_public:
          this.project.project_rol.id_project_rol === 3 &&
          this.user.person.types_person.id !== 3
            ? 1
            : this.isPublic
            ? 1
            : 0,
        project_id: this.project.project.id,
        person_id: this.user.person.id,
      };
      const { status } = await this.$store.dispatch("createLink", formData);

      if (status >= 200 && status < 300) {
        this.name = "";
        this.link = "";
        this.isPublic = false;
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.snackLinkForm = true;
        this.colorSnackLinkForm = "success";
        this.snackbarMessageLinkForm = "Link creado correctamente";
        this.loadingLinkForm = false;
      } else {
        this.snackLinkForm = true;
        this.colorSnackLinkForm = "danger";
        this.snackbarMessageLinkForm = "Error, intente nuevamente";
        this.loadingLinkForm = false;
      }
      this.$v.$reset();
      // this.$emit("action");
    },
    goToBack() {
      this.$emit("action");
    },
  },
  created() {
    this.isPublic =
      this.project.project_rol.id_project_rol === 3 &&
      this.user.person.types_person.id !== 3
        ? true
        : false;
  },
};
</script>
<style lang=""></style>
