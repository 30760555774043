var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"window-table-docs-step"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchDocs),callback:function ($$v) {_vm.searchDocs=$$v},expression:"searchDocs"}})],1),_c('v-data-table',{attrs:{"headers":_vm.docsheaders,"items":_vm.getDocs,"search":_vm.searchDocs},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.seeItem(item)}}},[_vm._v(" "+_vm._s(item.name.toLowerCase().includes("pdf") ? "picture_as_pdf" : "description")+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.is_public == 1 ? "Públ." : "Priv.")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icons-container-step-document"},[_c('a',{staticClass:"link-non-decoration",attrs:{"href":_vm.getLink(item.link),"target":"_blank","download":""}},[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.noAction()}}},[_vm._v(" visibility ")])]),(
            _vm.user.person.id === item.person_id ||
              _vm.user.person.types_person.id === 3
          )?_c('a',[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.editItemFileStep(item)}}},[_vm._v(" edit ")])]):_vm._e(),(
            _vm.user.person.id === item.person_id ||
              _vm.user.person.types_person.id === 3
          )?_c('a',[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.deleteItemFileStep(item.id)}}},[_vm._v(" delete_forever_outlined ")])]):_vm._e()]),_c('LoaderEntity',{attrs:{"loading":_vm.loadingFileStep}})]}}],null,true)}),_c('SnackBarEntity',{attrs:{"colorSnack":_vm.colorSnackFileStep,"snackbarMessage":_vm.snackbarMessageFileStep,"snack":_vm.snackFileStep},on:{"action":_vm.hideSnackBarFileStep}}),_c('DialogConfirmEntity',{attrs:{"showModal":_vm.dialogConfirmStep},on:{"action":_vm.deleteFileStep,"actionClose":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }