var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"navigation-projects",attrs:{"fixed":"","right":"","value":_vm.drawerLink}},[_c('v-row',{staticClass:"ndh-solid",attrs:{"id":"nav-drawer-header"}},[_c('div',[_c('span',{staticClass:"span-title"},[_vm._v(_vm._s(_vm.project.project.name)+" / ")]),_c('span',{staticClass:"span-subtitle"},[_vm._v("Links")])]),_c('div',[_c('button',{staticClass:"btn-close-drawer",attrs:{"icon":"","primary":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeLink.apply(null, arguments)}}},[_c('v-icon',[_vm._v("menu_open")])],1)])]),_c('div',[_c('div',{attrs:{"id":"container-search-drawer"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Buscar","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),(!_vm.addNewLink)?_c('div',{attrs:{"id":"nd-container"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.project.links,"item-key":"name","search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-link"},[_vm._v(" "+_vm._s(item.is_public === 1 ? "Público" : "Privado")+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"container-link",attrs:{"href":item.link,"target":"_blank"}},[_c('div',{staticClass:"badge-link"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("insert_link")]),_vm._v(" "+_vm._s(item.name)+" ")],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icons-container-navigation"},[_c('div',[(
                _vm.user.person.id === item.person_id ||
                  _vm.user.person.types_person.id === 3
              )?_c('a',[_c('i',{staticClass:"material-icons",attrs:{"id":"fvl-i-w"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("delete_forever_outlined ")])]):_vm._e()])])]}}],null,true)}),_c('LoaderEntity',{attrs:{"loading":_vm.loadingLink}}),_c('SnackBarEntity',{attrs:{"colorSnack":_vm.colorSnackLink,"snackbarMessage":_vm.snackbarMessageLink,"snack":_vm.snackLink},on:{"action":_vm.hideSnackBarLink}}),(_vm.drawerLink)?_c('DialogConfirm',{on:{"action":_vm.deleteLink}}):_vm._e()],1):_c('div',[_c('LinkForm',{on:{"action":_vm.cancelAddLink}})],1),_c('div',{attrs:{"id":"nd-commands"}},[_c('v-row',{staticClass:"fvl-w-100 d-flex justify-end"},[_c('v-btn',{staticClass:"fvl-btn green-primary",attrs:{"elevation":"1"},on:{"click":_vm.addLink}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_link")]),_vm._v("Añadir link ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }