<template>
  <v-navigation-drawer
    fixed
    right
    :value="drawerActivity"
    class="navigation-projects"
  >
    <!-- // Header Activity -->
    <v-row id="nav-drawer-header-activity">
      <div>
        <button
          icon
          class="btn-close-drawer"
          primary
          @click.stop="closeActivity"
        >
          <v-icon>menu_open</v-icon>
        </button>
      </div>
    </v-row>
    <!-- // Header Activity -->

    <!-- // Items Activity -->
    <v-list nav dense class="container-item-activity">
      <v-list-item
        v-for="(item, index) in sortedArray"
        :class="getClassItem(item.person_id)"
        :key="index"
        :id="index === sortedArray.length - 1 && 'last-element'"
      >


        <v-list-item-content :class="getClassContent(item.person_id)">
          <div class="content-title-message">
            <div class="name-person">
              {{ item.name }}
            </div>
            <div class="date-message">@{{ getFormatedDate(item.fecha) }}</div>
          </div>
          <div class="content-text-message">
            <span class="message-chat-activity"><p>{{ item.message }}</p></span>
          </div>
                <div class="d-flex flex-direction-row justify-end" >
                  <v-btn
                     x-small 
                     elevation="0" 
                     depressed
                     disabled
                     class="fvl-btn"
                      v-if="
                            user.person.id === item.person_id ||
                            user.person.types_person.id === 3
                            "
                  >
                     <span id="">
                       {{ item.is_public === 1 ? "Público" : "Privado" }}
                       </span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                     class="fvl-btn transparent-primary"
                     x-small elevation="0"
                     @click="deleteLink(item.id)"
                  >
                 
                   <span id="fvl-btn-span-secundary">
                      Eliminar
                       </span>
                
                  </v-btn>
                  </div>
       
        </v-list-item-content>
      </v-list-item>
      <div ref="bottom"></div>
    </v-list>
    <LoaderEntity :loading="loadingActivity" />
    <!-- // Form Activity -->
    <v-list nav dense class="div-form-activity">
      <form @submit.prevent="sendMessage">
        <div link class="content-send-comment">
          <div id="box-activity-project">
            <v-row id="form-header-box">
              <div>
                <span id="chat-activity-title" class="span-title">
                  {{ project.project.name }}
                </span>
              </div>
              <div>
                <v-btn
                  class="fvl-btn green-military"
                  elevation="0"
                  x-small
                  :class="getClassPrivate"
                  @click="setIsPublic(0)"
                  v-if="
                    project.project_rol.id_project_rol !== 3 ||
                      user.person.types_person.id === 3
                  "
                >
                  Privado
                </v-btn>
                <v-btn
                  class="fvl-btn green-military ml-1"
                  x-small
                  :class="getClassPublic"
                  elevation="0"
                  @click="setIsPublic(1)"
                >
                  Público
                </v-btn>
              </div>
              
            </v-row>
            <div id="container-send-activity-project" >
              <div class="text-area-activity-project">

                <v-container fluid>
                 <v-textarea
                    v-model="$v.comment.$model"
                    name="input-7-4"
                    filled
                    label="Escribe un comentario..."
                    auto-grow
                    rows="2"
                    row-height="25"
                     @blur="$v.comment.$touch()"
                  ></v-textarea>
                </v-container>

              
              </div>
                <div  class="fvl-btn-send-chat">
                  <v-btn
                  class="green-primary"
                  elevation="0"
                  icon
                  large
                  rounded
                  type="submit"
                  :disabled="$v.$invalid"
                  >
                    <v-icon>send</v-icon>
                  </v-btn>
                </div>
            </div>
          </div>
        </div>
      </form>
      <!-- <SnackBarEntity
        :colorSnack="colorSnackActivity"
        :snackbarMessage="snackbarMessageActivity"
        :snack="snackActivity"
        @action="hideSnackBarActivity"
      /> -->
    </v-list>
    <DialogConfirm @action="deleteComment" v-if="drawerActivity" />
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import {
  Button,
  DialogConfirm,
  LoaderEntity,
  SnackBarEntity,
} from "../../_commons";
import moment from "moment";
export default {
  name: "NavigationDrawerActivity",
  components: {
    Button,
    DialogConfirm,
    LoaderEntity,
    SnackBarEntity,
  },
  mixins: [validationMixin],
  validations: {
    comment: { required, minLength: minLength(1), maxLength: maxLength(100) },
  },
  data() {
    return {
      comment: "",
      loadingActivity: false,
      snackActivity: false,
      colorSnackActivity: "",
      snackbarMessageActivity: "",
      isPublic: 0,
    };
  },
  computed: {
    ...mapState({
      drawerActivity: (state) => state.projects.drawerActivity,
      project: (state) => state.projects.projectSelected,
      user: (state) => state.user,
      ui: (state) => state.ui,
    }),
    commentErrors() {
      const errors = [];
      if (!this.$v.comment.$dirty) return errors;
      !this.$v.comment.minLength &&
        errors.push("La longitud minima es de 1 caracteres");
      !this.$v.comment.maxLength &&
        errors.push("La longitud máxima es de 100 caracteres");
      !this.$v.comment.required &&
        errors.push("No puedes enviar un comentario vacío.");
      return errors;
    },
    getClassPrivate() {
      return this.isPublic === 0
        ? "btn-comments-protected selected-security"
        : "btn-comments-protected";
    },
    getClassPublic() {
      return this.isPublic === 1
        ? "btn-comments-protected selected-security"
        : "btn-comments-protected";
    },
    sortedArray: function() {
      function compare(a, b) {
        if (a.fecha < b.fecha) return -1;
        if (a.fecha > b.fecha) return 1;
        return 0;
      }
      this.scrollToElement();
      return this.project.comments.sort(compare);
    },
  },
  methods: {
    hideSnackBarActivity() {
      this.snackActivity = false;
    },
    closeActivity() {
      this.$store.dispatch("setDrawerActivityAction", false);
    },
    getClassItem(commentPersonId) {
      return commentPersonId === this.user?.person?.id
        ? "border-bottom-item container-activity container-activity-own"
        : "border-bottom-item container-activity container-activity-other";
    },
    getClassContent(commentPersonId) {
      return commentPersonId === this.user?.person?.id
        ? "item-messagge item-messagge-own"
        : "item-messagge item-messagge-other";
    },
    getFormatedDate(date) {
      const time = moment(date).format("h:mm");
      const now = moment();
      const dateMessage = moment(date);
      const diff = now.diff(dateMessage, "days");
      const dateFormated = `${time} - ${
        diff === 0
          ? "Hoy "
          : diff === 1
          ? "Ayer"
          : moment(date).format("DD-MM-YYYY")
      }`;
      return dateFormated;
    },
    setIsPublic(value) {
      this.isPublic = value;
    },
    async sendMessage() {
      this.loadingActivity = true;
      const formData = {
        message: this.comment,
        is_public:
          this.project.project_rol.id_project_rol === 3 && this.user.person.types_person.id !== 3 ? 1 : this.isPublic,
        person_id: this.user.person.id,
        project_id: this.project.project.id,
      };
      const { status } = await this.$store.dispatch("createComment", formData);
      if (status >= 200 && status < 300) {
        this.comment = "";
        this.snackActivity = true;
        this.colorSnackActivity = "success";
        this.snackbarMessageActivity = "Mensaje enviado correctamente";
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);
        this.loadingActivity = false;
      } else {
        this.snackActivity = true;
        this.colorSnackActivity = "danger";
        this.snackbarMessageActivity = "Error, intente nuevamente";
        this.loadingActivity = false;
      }
      this.$v.$reset();
    },
    async deleteLink(id) {
      const msg = `¿Deseas eliminar este comentario?`;
      await this.$store.dispatch("setIdItemDialogAction", id);
      await this.$store.dispatch("setMsgDialogConfirmAction", msg);
      await this.$store.dispatch("setDialogShowAction");
    },
    async deleteComment() {
      this.loadingActivity = true;
      await this.$store.dispatch("setDialogShowAction");
      const { status } = await this.$store.dispatch(
        "deleteComment",
        this.ui.idItemDialog
      );
      if (status >= 200 && status < 300) {
        const payload = {
          idProject: this.$route.params.idproject,
          idperson: this.user.person.id,
        };
        await this.$store.dispatch("getProjectDetailById", payload);

        this.snackActivity = true;
        this.colorSnackActivity = "success";
        this.snackbarMessageActivity = "Mensaje eliminado correctamente";
        this.loadingActivity = false;
      } else {
        this.snackActivity = true;
        this.colorSnackActivity = "danger";
        this.snackbarMessageActivity = "Error, intente nuevamente";
        this.loadingActivity = false;
      }
    },
    scrollToElement() {
      const element = document.getElementById("last-element");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  updated() {
    this.scrollToElement();
  },
  created() {
    this.loadingActivity = false;
    this.snackActivity = false;
    this.isPublic = this.project.project_rol.id_project_rol === 3 && this.user.person.types_person.id !== 3 ? 1 : 0;
  },
};
</script>
<style lang=""></style>
